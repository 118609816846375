import React from "react"
import PropTypes from "prop-types"

import {
  SvgIconSet,
} from "../assetComponents"

class OuterContainer extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { children, className } = this.props
    return <div className={className}>
      <SvgIconSet />
      <div className="off-canvas-content">
        {children}
      </div>
    </div>
  }
}

export { OuterContainer }
