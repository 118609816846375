import React from "react"
import PropTypes from "prop-types"

class IntermediateContainer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: "",
  }

  render() {
    const { children, className } = this.props
    return <div className={`section-user-experience apps-tab ${className}`}>
      <div className="grid-container mob-bgimg">
        <div className="grid-x grid-margin-x">
          {children}
        </div>
      </div>
    </div>
  }
}

export { IntermediateContainer }
