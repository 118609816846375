import React from "react"
import PropTypes from "prop-types"

class InnerContainer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { children } = this.props
    return <div className="cell">
      <div className="tabs-content" data-tabs-content="apps-tabs">
        <div className="tabs-panel is-active tabsScroll" role="tabpanel">
          <div className="grid-container p-0">
            <div className="grid-x grid-margin-x">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export { InnerContainer }
